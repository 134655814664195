import { Injectable } from '@angular/core'
import { filter, map, takeWhile, tap } from 'rxjs/operators'
import { UserService } from '../user/user.service'
import { AdministrationService } from '../administration/administration.service'
import { BankAccountService } from '../administration/bankaccounts.service'
import { environment } from '../../../environments/environment'
import { User } from '../../domain/user/user.model'
import { Administration } from '../../domain/administration/administration.model'
import { AdministrationBankAccount } from '../../domain/bankAccount/administration-bank-account.model'
import { UserRoleTypes } from '../../domain/user/user-role-types.constants'
import { LoginService } from '../../core/security/login.service'
import { AdministrationSettings, BillingSettings } from '../../domain/administration/administration-settings.model'
import { BillingService } from '../administration/billing.service'
import { AdministrationRoleTypes } from '../../domain/administration/administration-role-types.constants'
import { NavigationEnd, Router } from '@angular/router'
import { SentryErrorHandlerService } from './sentry-error-handler.service'
import { forkJoin, Observable } from 'rxjs'
import { AdblockerHelper } from '../helpers/adblocker.helper'
import { ScriptService } from '../helpers/script.service'
import { LaravelService } from '../banking/laravel.service'
import { SwanOnboarding } from '../../domain/swan/onboarding.model'
import { SwanAccount } from '../../domain/swan/account.model'
import moment from 'moment'
import { TranslateService } from '@ngx-translate/core'

@Injectable()
export class SegmentHelper {
  origin: string
  private shouldTrack = true

  constructor(
    private readonly _user: UserService,
    private readonly _administration: AdministrationService,
    private readonly _bank: BankAccountService,
    private readonly _loginService: LoginService,
    private readonly _billing: BillingService,
    private readonly _laravel: LaravelService,
    private readonly _scriptService: ScriptService,
    private readonly _router: Router,
    private readonly _sentry: SentryErrorHandlerService,
    private readonly _adblocker: AdblockerHelper,
    private readonly _translate: TranslateService,
  ) {
    // On Emitting signout; log the sign out.
    this._loginService.onLoggedOut.subscribe(() => this.signout())
    this._user.onIdentifyUser.subscribe(() => this.identifyUser())

    this.initPageTracking()
    this.initTrackingWithUserData()
  }

  /**
   * Replace Intercom with mocked
   * no-op function when blocked.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private noop = () => {}

  private get window(): any {
    return window as any
  }

  private get analytics(): any {
    /**
     * If there is no analytics
     * (i.e. ad-blocker, content-blocker)
     * Just return and proceed as usual.
     */
    this._adblocker.isBlockingTracking('High').subscribe((active) => {
      if (active) return this.noop
    })

    return this.window.analytics
  }

  /**
   * Load Segments' Analytics.js (snippet from Segments' Quickstart).
   * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/
   */
  loadSegment(): void {
    // Create a queue, but don't obliterate an existing one!
    const analytics = ((window as any).analytics = (window as any).analytics || [])

    // If the real analytics.js is already on the page, or invoked twice return.
    if (analytics.initialize) return

    // If the snippet was invoked already show an error.
    if (analytics.invoked) {
      if (window.console && console.error) {
        console.error('Segment snippet included twice.')
      }

      return
    }

    // Invoked flag, to make sure the snippet
    // is never invoked twice.
    analytics.invoked = true

    // A list of the methods in Analytics.js to stub.
    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
      'addSourceMiddleware',
      'addIntegrationMiddleware',
      'setAnonymousId',
      'addDestinationMiddleware',
    ]

    // Define a factory to create stubs. These are placeholders
    // for methods in Analytics.js so that you never have to wait
    // for it to load to actually record data. The `method` is
    // stored as the first argument, so we can replay the data.
    analytics.factory = function (method) {
      return function () {
        // eslint-disable-next-line prefer-rest-params
        const args = Array.prototype.slice.call(arguments)
        args.unshift(method)
        analytics.push(args)

        return analytics
      }
    }

    // For each of our methods, generate a queueing stub.
    for (let i = 0; i < analytics.methods.length; i++) {
      const key = analytics.methods[i]
      analytics[key] = analytics.factory(key)
    }

    /**
     * If the adblocker helper says the
     * browser is blocking Segment's script,
     * we opt to call it through CF proxy instead.
     *
     * (Slightly altered from this URL: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/#step-2-copy-the-segment-snippet)
     * Define a method to load Analytics.js from our CDN,
     * and that will be sure to only ever load it once.
     */
    const bypass = `https://analyse.tellow.nl/ajs/${environment.segmentApiKey}`
    const regular = `https://cdn.segment.com/analytics.js/v1/${environment.segmentApiKey}/analytics.min.js`
    this._adblocker
      .isBlockingTracking()
      .subscribe((active) => this._scriptService.loadScript(active ? bypass : regular).subscribe(() => (analytics.SNIPPET_VERSION = '4.15.2')))
  }

  /**
   * Init .page() call
   */
  initPageTracking(): void {
    this._router.events
      .pipe(
        takeWhile(() => this.shouldTrack),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe((event: NavigationEnd) => {
        this.logPageRoute(event.url)
      })
  }

  /**
   * Init tracking with user data. Tracking for ROLE_ADMIN gets blocked
   */
  initTrackingWithUserData(): Observable<User> {
    return this._user.user.pipe(
      map((user: User) => {
        this.shouldTrack = user.role !== UserRoleTypes.ADMIN

        return user
      }),
    )
  }

  /**
   * Log the visited route to Segment
   * @param url
   */
  logPageRoute(url: string): void {
    if (!url || url === '' || url === '/') {
      return
    }

    const wordsToSearchFor = ['magic-token', 'bankrekening']

    const hasToBeObfuscated = wordsToSearchFor.some((word) => url.includes(word))

    if (hasToBeObfuscated) {
      const obfuscater = (input: string) => input.substr(0, input.lastIndexOf('/'))

      this.analytics.page({
        referrer: window.document.referrer,
        path: obfuscater(window.location.pathname),
        search: '',
        title: window.document.title,
        url: obfuscater(window.document.location.href),
      })

      return
    } else if (!hasToBeObfuscated) {
      this.analytics.page()
    }
  }

  /**
   * Fetches all required user data and sends it to Segment.
   */
  identifyUser(additional?: { [key: string]: string | object }): void {
    this.loadUserData().subscribe(
      ({ user, administration, banks, settings, billing, swanOnboarding, swanAccount, hasSwanIban }) => {
        this._sentry.trackUser(user.id.toString(), settings.id.toString())
        this.pushUserInfoToSegment(user, administration, banks, settings, billing, swanOnboarding, swanAccount, hasSwanIban, additional)
      },
      (err: any) => console.error(err),
    )
  }

  /**
   * Track event in Segment.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  track(event: string, properties?: object, options?: object): void {
    if (!event) {
      return
    }

    // ------------- Example: ------------------
    // analytics.track('Article Completed', {
    //   title: 'How to Create a Tracking Plan',
    //   course: 'Intro to Analytics',
    // });
    // -----------------------------------------

    this.analytics.track(event, properties)
  }

  /**
   * Collects and sends provided userdata to Segment.
   */
  private pushUserInfoToSegment(
    user: User,
    administration: Administration,
    banks: AdministrationBankAccount[],
    settings: AdministrationSettings,
    billing: BillingSettings | any,
    swanOnboarding: SwanOnboarding | null,
    swanAccount: SwanAccount | null,
    hasSwanIban: boolean,
    additional?: { [key: string]: string | object },
  ): void {
    if (!user || !this.shouldTrack) {
      return
    }

    const serviceCategory = ['Accounting']
    if (hasSwanIban) serviceCategory.push('Banking')

    this.analytics.identify(user.id, {
      // User
      firstName: user.first_name,
      lastName: user.last_name,
      userId: user.id,
      origin: UserService.getUserTypeName(user.origin_id),
      email: user.email,
      phone: user.phone_number,
      createdAt: user.created_at,
      userRole: user.role,
      administrationRole: administration.user_roles[0] ?? null,
      isBeta: user.is_beta,
      hasValidatedEmail: user.validated_email,
      isSubscribedToNewsletter: user.is_subscribed_to_newsletter,
      requiresPasskeyLogin: user.requires_passkey_login,
      userLanguage: this._translate.currentLang,
      serviceCategory,

      // Administration
      administrationId: administration.user_roles.find((role) => role === AdministrationRoleTypes.OWNER) ? administration.id : null,
      administrationStoppedAt: administration.user_roles.find((role) => role === AdministrationRoleTypes.OWNER) ? administration.stopped_at : null,
      companyName: administration ? administration.name : '',

      // Banks
      hasBank: banks && banks.length && banks.length > 0,
      bankNames: banks.map((bank) => bank.bank.name),

      // Settings
      startDate: settings.start_date,
      legalForm: settings.legal_form,
      sbi: settings.sbi,
      cocNumber: settings.coc_number,
      vatNumber: settings.vat_number,
      address: {
        street: settings.address_street,
        number: settings.address_number,
        addition: settings.address_number_addition,
        postalCode: settings.address_postal_code,
        city: settings.address_city,
        country: settings.address_country,
      },
      vatDeclarationPeriod: settings.vat_declaration_period,
      hasVat: settings.has_vat,
      hasCar: settings.has_car,
      hasRealEstate: settings.has_real_estate,
      website: settings.website,

      // Billing
      currentPlan: billing.data.plan?.name ?? null,
      currentPlanDuration: billing.data.plan?.lockUserToThisPlanForMonths === 12 ? 'yearly' : 'monthly',
      currentPlanType: billing.type,
      trialExpirationDate: billing.data?.trialEndsAt ?? null,

      // Swan banking
      swanOnboardingCreatedAt: swanOnboarding?.created_at ? moment(swanOnboarding.created_at).format('YYYY-MM-DD HH:mm:ss') : null,
      swanOnboardingStatus: swanOnboarding?.status ?? null,
      swanAccountCreatedAt: swanAccount?.created_at ? moment(swanAccount.created_at).format('YYYY-MM-DD HH:mm:ss') : null,
      swanAccountUpdatedAt: swanAccount?.updated_at ? moment(swanAccount.updated_at).format('YYYY-MM-DD HH:mm:ss') : null,
      swanIsFullyVerified: hasSwanIban,
      swanBankAccountBalance: swanAccount?.available_balance ?? null,

      /**
       * Intercom + Segment implementation
       * @see https://app.intercom.com/a/apps/xrg98po3/settings/identity-verification/web
       */
      user_hash: localStorage.getItem('intercom'),
      // Spread any additional entries (for i.e. sourcebuster)
      ...additional,
    })
  }

  /**
   * Helper method to simply load all required data for Segment
   */
  private loadUserData(): Observable<{
    user: User
    administration: Administration
    banks: AdministrationBankAccount[]
    settings: AdministrationSettings
    billing: BillingSettings
    swanOnboarding: SwanOnboarding | null
    swanAccount: SwanAccount | null
    hasSwanIban: boolean
  }> {
    const user: Observable<User> = this._user.user.pipe(
      tap((user) => {
        this.shouldTrack = user?.role !== UserRoleTypes.ADMIN
      }),
    )

    return forkJoin({
      user: user,
      administration: this._administration.defaultAdministration,
      banks: this._bank.getAllBankAccounts(),
      settings: this._administration.defaultAdministrationSettings,
      billing: this._billing.getInfo(true),
      swanOnboarding: this._laravel.getOnboarding$,
      swanAccount: this._laravel.getBankAccount$,
      hasSwanIban: this._laravel.hasIBAN$,
    })
  }

  private reset(): void {
    this.analytics.reset()
  }

  private signout = () => {
    this.track('Logout')
    this.reset()
  }
}
